
    import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
    import { defineComponent, reactive, UnwrapRef, ref } from 'vue';
    import { useStore } from "vuex";
    import { findCurrentUser } from '@/API/user'
    import { useRouter } from 'vue-router';

    interface FormState {
        user: string;
        password: string;
    }

    export default defineComponent({
        name: "Login",
        setup() {
            const store = useStore();

            const { push } = useRouter();

            const visible = ref(false);

            const formState: UnwrapRef<FormState> = reactive({
                user: '',
                password: '',
            });
            const handleFinish = (values: FormState) => {
                store.commit('updateAuthorization', `${formState.password}.${formState.user}`);
                store.dispatch('updateUser').then((res) => {
                    push('/')
                })
            };
            return {
                formState,
                handleFinish,
                visible
            };
        },
        components: {
            UserOutlined,
            LockOutlined,
        },

    })
